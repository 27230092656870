import React from 'react';
import cls from 'classnames';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

interface LazyImageProps {
  alt?: string;
  className?: string;
  src: string;
  srcset?: string;
  sizes?: string;
  width?: number;
  height?: number;
}

export const LazyImage = ({
  alt,
  className,
  src,
  srcset,
  sizes,
  width,
  height,
}: LazyImageProps ) => (
  <img
    alt={alt}
    className={cls( 'lazyload', className )}
    data-src={src}
    data-srcset={srcset}
    data-sizes={sizes}
    width={width}
    height={height}
  />
);
